// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dzierzawa-kserokopiarek-js": () => import("./../../../src/pages/dzierzawa-kserokopiarek.js" /* webpackChunkName: "component---src-pages-dzierzawa-kserokopiarek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kserokopiarki-czarno-biale-js": () => import("./../../../src/pages/kserokopiarki/czarno-biale.js" /* webpackChunkName: "component---src-pages-kserokopiarki-czarno-biale-js" */),
  "component---src-pages-kserokopiarki-kolorowe-js": () => import("./../../../src/pages/kserokopiarki/kolorowe.js" /* webpackChunkName: "component---src-pages-kserokopiarki-kolorowe-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-serwis-js": () => import("./../../../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */)
}

